#schedule {
	#maps {
		margin-bottom: 40px;
		.map {
			&__container {

				li {
					&:first-child {
						margin-bottom:30px;
					}
					iframe {
						display: block;
						width: 100%;
					}
				}
			}
		}
		section {
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
			ul {
				list-style: none;
				li {

				}
			}
		}
	}
	#weekly {
		&__container {
			width: 100%;
			overflow: scroll;
		}
		table {
			width: 100%;
			@include mq-sp {
				width: 150%;
			};
			caption {
				@include mq-sp {
					text-align:left;
				};
			}
			th {
				padding:0.5em 0;
				background-color: getColor('bordeaux');
				border-bottom:1px solid getColor('white');
				border-right:1px solid getColor('white');
				color: getColor('white');
				font-weight: normal;
				&:last-of-type {
					border-right:0;
				}
				@include mq-sp {
					padding:0.5em 0.2em;
				};
			}
			td {
				padding:1em 0.5em;
				background-color: getColor('light-gray');
				border-bottom:1px solid getColor('white');
				border-right:1px solid getColor('white');
				font-size: px2rem(12,14);
				text-align: center;
				&:last-of-type {
					border-right:0;
				}
				@include mq-sp {
					width: 90px;
				};
				span {
					display: block;
					padding:0.3em ;
					background-color: #ddd;
					border-radius: 4px;
				}
			}
			tr:last-of-type {
				td {
					border-bottom:0;
				}
			}
		}
	}
}
