/*!
 * Main
 */
#main {
	width: 580px;
	margin: 0 auto 80px 0;
	@include mq-sp {
		width: auto;
		margin: 0 px2per(15,375) 40px;
	};
}
.main {
	&--center {
		margin: 0 auto 80px !important;
		@include mq-sp {
			margin: 0 px2per(15,375) 40px !important;
		};
	}
}
