.m-title {
	position: relative;
	width: 960px;
	margin:0 auto 80px;
	line-height: 1;
	font-family: $_font-family-noto;
	font-weight: 900;
	color: getColor('gray');
	text-align: center;
	@include mq-sp {
		width: 100%;
		margin:0 auto 30px;
	};
	span {
		position: relative;
		background:getColor('white');
		z-index: 2;
		padding:0 10px;
		line-height: 1;
		font-size: px2rem(24,14);
		vertical-align: middle;
		@include mq-sp {
			font-size: px2rem(16,14);
		};
	}
	&:after {
		position: absolute;
		content: '';
		display: block;
		top:50%;
		left:0;
		width: 100%;
		height:1px;
		border-bottom:1px solid #D8D8D8;
		z-index: 1;
	}
	&__information {
		margin-top:50px;
		@include mq-sp {
			margin-top:80px;
		};
	}
}
