/*!
 * Lang
 */
 .m-lang {
	display: flex;
	list-style: none;
	margin:0 auto 0 0;
	padding:0;
	line-height: 24 / 18;
	@include mq-sp {
		display: none;
	};
	&__button {
		width: 2.5em;
		text-align: center;
		a {
			display: block;
			color:getColor('white');
			font-size: px2rem(18 , 14);
			background: #72171B;
			transition: all .2s ease;
			&:hover {
				text-decoration: none;
				background:getColor('white');
				color:getColor('bordeaux');
			}
		}
		&.is-active {
			a {
				background: getColor('white');
				color: getColor('bordeaux');
			}
		}
	}
 }
