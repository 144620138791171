/*!
 * Footer
 */

.footer {
	height: 50px;
	overflow-y: hidden;
	background:  getColor('dark-gray');
	text-align: center;
	line-height: 50px;
	color: getColor('white');
}
