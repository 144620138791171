.form {
	@include mq-sp {
		display: block;
	};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	list-style: none;
	li {
		width: 280px;
		height: 240px;
		margin-bottom: 20px;
		background-color: #7C2B2C;
		background-size:40px auto;
		background-image:-webkit-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:-o-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:linear-gradient(to right, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-repeat: repeat;
		border-radius: 15px;
		text-align: center;
		cursor: pointer;
		@include mq-sp {
			width: 100%;
			height: auto;
			margin-bottom: 20px;
			padding:20px 0;
		};
		&:hover {
			opacity: 0.8;
		}
		&:nth-of-type(odd) {
			margin-right:auto;
		}
		a {
			width: 100%;
			height: 100%;
			color: getColor('white');

			&:hover {
				text-decoration: none;

			}
		}
		span {
			display: block;
			line-height: 1;
		}
	}
	&__title {
		margin: 80px 0 0.5em;
		font-size:px2rem(18,14);
		font-family: $_font-family-noto;
		font-weight: 900;
		@include mq-sp {
			margin: 0 0 0.5em;
		};
	}
	&__sub {
		margin-bottom: 1em;
	}
	&__download {
		width: 80%;
		padding:0.4em 1em 0.4em 0;
		margin:0 auto;
		background: url('../../images/dist/icon-pdf.png') no-repeat 90% center getColor('white');
		color:getColor('bordeaux');
		font-size:px2rem(24,14);
		font-family: $_font-family-noto;
		font-weight: 900;
		&.is-xls {
			padding:0.4em 1em;
			background: getColor('white');
		}
	}
}
