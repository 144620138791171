// px
@for $i from 0 through 20 {
	$num: $i * 5;
	.u-p-#{$num} {
		padding: #{$num}px !important;
	}
	.u-pt-#{$num} {
		padding-top: #{$num}px !important;
	}
	.u-pr-#{$num} {
		padding-right: #{$num}px !important;
	}
	.u-pb-#{$num} {
		padding-bottom: #{$num}px !important;
	}
	.u-pl-#{$num} {
		padding-left: #{$num}px !important;
	}
}

// em
@for $i from 1 through 8 {
	$num: $i / 4;
	$classnum: if($num * 100 >= 100, $num * 100, "0" + $num * 100);
	.u-p-em-#{$classnum} {
		padding: #{$num}em !important;
	}
	.u-pt-em-#{$classnum} {
		padding-top: #{$num}em !important;
	}
	.u-pr-em-#{$classnum} {
		padding-right: #{$num}em !important;
	}
	.u-pb-em-#{$classnum} {
		padding-bottom: #{$num}em !important;
	}
	.u-pl-em-#{$classnum} {
		padding-left: #{$num}em !important;
	}
}