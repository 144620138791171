@font-face {
	font-family: "Noto Sans JP";
	font-weight: 250;
	src:	url('../../fonts/NotoSansCJKjp-Thin.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Thin.woff') format('woff'),
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 300;
	src:	url('../../fonts/NotoSansCJKjp-Light.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Light.woff') format('woff');
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 350;
	src:	url('../../fonts/NotoSansCJKjp-DemiLight.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-DemiLight.woff') format('woff');
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 400;
	src:	url('../../fonts/NotoSansCJKjp-Regular.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Regular.woff') format('woff');
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 500;
	src:	url('../../fonts/NotoSansCJKjp-Medium.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Medium.woff') format('woff');
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 700;
	src:	url('../../fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Bold.woff') format('woff');
}
@font-face {
	font-family: "Noto Sans JP";
	font-weight: 900;
	src:	url('../../fonts/NotoSansCJKjp-Black.woff2') format('woff2'),
			url('../../fonts/NotoSansCJKjp-Black.woff') format('woff');
}