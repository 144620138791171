#coach {
	#main {
		section {
			ul {
				list-style: none;
			}
		}
	}
}
.coach {
	display: flex;
	margin-bottom: 50px;
	@include mq-sp {
		flex-direction: column;
	};
	&__head {
		border-top:1px solid #CBCBCB;
		border-bottom:1px solid #CBCBCB;
		padding:15px 0;
		margin-bottom:2em;
		h3 {
			font-size: px2rem(24,14);
			color: getColor('bordeaux');
			@include mq-sp {
				text-align: center;
			};
		}
		p {
			font-size: px2rem(12,14);
			@include mq-sp {
				text-align: center;
			};
			br {
				display: none;
				@include mq-sp {
					display: block;
				};
			}
			span {
				@include mq-sp {
					display: none;
				};
			}
		}
	}
	&__content {
		width:400px;
		@include mq-sp {
			width: 100%;
		};
		section {
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
			h4 {
				display: inline-block;
				padding:0.2em 1em;
				margin-bottom: 1em;
				background:#FAFAFA;
				border:1px solid #979797;
				border-radius:4px;
				font-weight: normal;
			}
			ul {
				list-style: none;
				li {
					display: flex;
					margin-bottom: 0.8em;
					font-size: px2rem(12,14);
					&:last-of-type {
						margin-bottom: 0;
					}
					span {
						&:first-of-type {
							padding:0.2em 1em;
							margin-right:1em;
							background:#B4B4B4;
							border-radius:4px;
							color: getColor('white');
						}
					}
				}
			}
		}
		&.opened {
			.coach__btn {
				span:last-child {
					transform: rotate(0);
				}
			}
		}
	}
	&__prof {
		@include mq-sp {
			display: none;
			padding:10px;
			background:#eee;
		};
	}
	&__btn {
		display: none;
		position: relative;
		padding:0.5em 0 ;
		background:#eee;
		color:#888;
		text-align: center;
		text-decoration: none;
		@include mq-sp {
			display: block;
		};
		&:hover {
			color:#888;
			text-decoration: none;
		}
	}
	&__icon {
		position: absolute;
		display: block;
		width: px2per(20,375);
		height: auto;
		padding-top: px2per(20,375);
		margin: px2per(-10,375) px2per(20,375) 0 0;
		top: 50%;
		right: 0;
		span {
			width: 100%;
			height: 1px;
			background-color: #888;
			display: block;
			position: absolute;
			top: 50%;
			transition: all .3s ease;
			&:last-child {
				transform: rotate(90deg);
			}
		}
	}
	&__img {
		width: 180px;
		@include mq-sp {
			width: auto;
			text-align: center;
			margin-bottom: 10px;
		};
		img {
			width: 160px;
			@include mq-sp {
				width: 70%;
			};
		}
	}
}
