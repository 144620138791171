#about {
	&__description {
		line-height: 2;
	}
	#mission {
		margin-bottom: 40px;
	}
	#club , #school , #registration {
		margin-bottom: 40px;
		section {
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	#private {
		position: relative;
		p {
			line-height: 2;
			padding-bottom: 160px;
			@include mq-sp {
				padding: 0;
				br {
					display: none;
				}
			};

		}
		img {
			position: absolute;
			bottom:0;
			right:0;
			@include mq-sp {
				position: relative;
				bottom:auto;
				right: auto;
			};
		}
	}
}
