:root {
	font-size: $_base-font-size;
}

html {
	font-family: $_font-family;

}

body , html {
	margin:0;
}

body * {
	box-sizing: border-box;
}

h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}
}

p, ul, ol {
	margin-bottom: 1em;
	&:last-child {
		margin-bottom: 0;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

figure {
	text-align: center;
}

a {
	text-decoration: none;
	color: $_text-link;
	img {
		transition: opacity .1s ease;
		border-style: none;
	}
	&:hover {
		text-decoration: underline;
		color: $_text-link-hover;
		img {
			opacity: $opacity;
		}
	}
}
main {
    display: block;
}
a:focus, *:focus { outline:none; }
