#link {

}
.link {
	list-style: none;
	margin-bottom: 60px !important;
	&__item {
		margin-bottom:40px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&__box {
		display: flex;
		align-items: center;
		@include mq-sp {
			display: block;
			img {
				display: none;
			}
		};
	}
	&__content {
		margin-left:20px;
		padding:0.5em 1em;
		border-left:2px solid getColor('bordeaux');
		font-family: $_font-family-noto;
		@include mq-sp {
			margin-left:0;
			padding:0.5em 1em;

		};
		h3 {
			font-weight: 400;
		}

	}
	&__desc {
		margin-top: 0.5em;
		font-size: px2rem(12,14);
		color: getColor('gray2');
	}
	&__img {
		max-width: 240px;
	}
}
