#contact {
	form {

		input[type="submit"] {
			display: block;
			width: 100%;
			padding: 0.5em;
			background: getColor('green');
			font-size: px2rem(18,14);
			font-family: $_font-family-noto;
			color: getColor('white');
			line-height: 1;
			transition: all .2s ease;
			&:hover {
				opacity: 0.7;
			}
		}
	}
	.form {
		&__label {
			display: block;
			margin-bottom: 20px;
			span {
				display: block;
				margin-bottom: 0.2em;
			}
			&.has-error {
				color:#f00;
				.form__input , .form__textarea {
					border-color: #f00 !important;
				}
			}
		}
		&__input {
			width: 60%;
			border:1px solid #ddd;
			padding:0.4em 1em;
			@include mq-sp {
				width: 100%;
			};
			&--subject , &--email {
				width: 100%;
			}
		}
		&__textarea {
			width: 100%;
			border:1px solid #ddd;
			padding:0.4em 1em;
		}
	}
}
