.m-list {
	padding-left:1em;
	line-height: 2;
	.m-table {
		text-align: center !important;
	}
	& > li {
		position: relative;
		list-style: none;
		&:before {
			position: absolute;
			top:0.75em;
			left:-1em;
			content: '';
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 3px;
			background-color: getColor('bordeaux');
		}
		ul {
			padding-left:18px;
			li {
				position: relative;
				list-style: none;
				&:before {
					position: absolute;
					top:0.5em;
					left:-1em;
					content: '';
					display: block;
					width: 6px;
					height: 6px;
					border-radius: 3px;
					border: 1px solid getColor('bordeaux')
				}
			}
		}
	}
}
.m-list-sec {
	display: flex;
	list-style: none;
	align-items: center;
	@include mq-sp {
		flex-direction: column;
	};
	li {
		display: flex;
		list-style: none;
		justify-content: center;
		align-items: center;
		flex-direction:column;
		width: 30%;
		height:50px;
		margin-right:10px;
		background: getColor('white');
		border: 1px solid #979797;
		border-radius: 25px;
		text-align: center;
		font-family: $_font-family-noto;
		font-weight: 900;
		font-size: px2rem(12,14);
		@include mq-sp {
			flex-direction: row;
			width: 100%;
			height: auto;
			margin-right:0;
			margin-bottom: 10px;
			padding:0.7em 0;
			flex-direction: row;;
		};
		span {
			color: getColor('bordeaux')
		}
		&:last-of-type {
			margin-right:none;
		}
		div:last-child {
			@include mq-sp {
				margin-left:10px;
			};
		}
	}
}

.m-list-sub {
	list-style: none;
	font-size:px2rem(12,14);
	li {
		margin-bottom:6px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
