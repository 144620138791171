.m-table {
	width: 100%;
	@include mq-sp {
		width: 150%;
	};
	caption {
		@include mq-sp {
			text-align:left;
		};
	}
	th {
		padding:0.5em 0;
		background-color: getColor('bordeaux');
		border-bottom:1px solid getColor('light-gray');
		border-right:1px solid getColor('light-gray');
		color: getColor('white');
		font-weight: normal;
		&:last-of-type {
			border-right:0;
		}
		@include mq-sp {
			padding:0.5em 0.2em;
		};
	}
	td {
		padding:1em 0.5em;
		background-color: getColor('white');
		border-bottom:1px solid getColor('light-gray');
		border-right:1px solid getColor('light-gray');
		font-size: px2rem(12,14);
		text-align: center;
		&:last-of-type {
			border-right:0;
		}
		@include mq-sp {
			width: 90px;
		};
		span {
			display: block;
			padding:0.3em ;
			background-color: #ddd;
			border-radius: 4px;
		}
	}
	tr:last-of-type {
		td {
			border-bottom:0;
		}
	}
}
