/*!
 * Side
 */
#side {
	width: 280px;
	margin-bottom:80px;
	@include mq-sp {
		width: auto;
		margin-bottom:0;
		padding:20px 0;
		background-color: #7C2B2C;
		background-size:20px auto;
		background-image:linear-gradient(to right, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-repeat: repeat;
	};
	&__event , &__link {
		padding:20px 0;
		background-color: #7C2B2C;
		background-size:20px auto;
		background-image:linear-gradient(to right, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-repeat: repeat;
		border-radius: 15px;
		text-align: center;
		color: getColor('white');
		@include mq-sp {
			background: none;
			padding:0;
		};
		h2 {
			font-size: px2rem(24,14);
			font-family: $_font-family-noto;
			font-weight: 900;
		}
		li {
			margin-bottom:15px;
			&:last-of-type {
				margin:0;
			}
		}
	}
	&__event {
		margin-bottom: 20px;
		a {
			display: block;
			background-color: #fff;
			text-align: center;
			margin: 1rem;
		}
		img {
			width: 240px;
		}
	}
}
