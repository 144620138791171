/*!
 * Nav
 */

.nav {
	display: flex;
	margin-left: auto;
	@include mq-sp {
		display: none;
		position: absolute;
		width: 100%;
		top:60px;
		left:0;
	};
	&_link {
		display: block;
		padding:4px 0;
		margin:0 10px;
		color: getColor('black');
		border-bottom: 1px dotted  getColor('black');
		line-height: 1;
		transition: all .2s ease;
		@include mq-sp {
			position: relative;
			padding: 1em;
			&:before {
				position: absolute;
				display: block;
				content: '';
				top:50%;
				left:0;
				width: 0;
				height: 0;
				margin-top:-5px;
				border-style: solid;
				border-width: 5px 0 5px 6px;
				border-color: transparent transparent transparent getColor('bordeaux');
			}
		};
		&:hover {
			color: getColor('bordeaux');
			border-bottom: 1px solid  getColor('bordeaux');
			text-decoration: none;
		}
		&.is-active {
			color: getColor('bordeaux');
			border-bottom: 1px solid  getColor('bordeaux');
		}
	}
	&_list {
		display: flex;
		list-style: none;
		@include mq-sp {
			display: block;
			background: rgba(255, 255, 255, 0.95);
		};

	}
	&_item {
		position: relative;
		@include mq-sp {
			&:last-of-type {
				a {
					border-bottom: 0;
				}
			}
		};
		&._has-child {
			.nav_link {
				&:before {
					@include mq-sp {
						display: none;
					};
				}
			}
			&:hover{
				.nav_link {
					&:hover {
						color: getColor('black');
						border-bottom: 1px dotted  getColor('black');
					}
				}
				.nav_child {
					top: 18px;
					visibility: visible;
					opacity: 1;
					@include mq-sp {
						top: auto;
					};
				}
			}
		}
	}
	&_child {
		position: absolute;
		top: 8px;
		width: 240px;
		padding: 1em 0;
		opacity: 0;
		transition: all .2s ease;
		@include mq-sp {
			position: relative;
			top: auto;
			opacity: 1;
		};
		li {
			background: getColor('bordeaux');
			border-bottom: 1px solid rgba(255, 255, 255, .1);
			@include mq-sp {
				background: none;
			};
			a {
				display: block;
				padding: 1em;
				color: getColor('white');
				@include mq-sp {
					position: relative;
					padding: .5em 1em;
					margin: 0 2em;
					color: getColor('black');
					&:before {
						position: absolute;
						display: block;
						content: '';
						top:50%;
						left:0;
						width: 0;
						height: 0;
						margin-top:-5px;
						border-style: solid;
						border-width: 5px 0 5px 6px;
						border-color: transparent transparent transparent getColor('bordeaux');
					}
				};

			}
		}
	}
}

.btn-menu {
	display: none;
	@include mq-sp {
		position: absolute;
		display: block;
		width: 36px;
		height: 28px;
		top:50%;
		right:10px;
		margin-top: -14px;
		span {
			display: block;
			position: absolute;
			top:50%;
			width: 100%;
			height: 3px;
			border-radius: 3px;
			background-color: getColor('white');
			transition: all .3s ease;
			&:nth-of-type(1) {
				margin-top: -12px;
			}
			&:nth-of-type(2) {
			}
			&:nth-of-type(3) {
				margin-top: 12px;
			}
		}
	};
}
