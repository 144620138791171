/*!
 * Box
 */
.m-box {
	padding:20px 35px;
	margin-bottom: 20px;
	background: getColor('light-gray');
	@include mq-sp {
		padding:1em px2per(15,375);
		margin-bottom: 20px;
	};
	&:last-of-type {
		margin-bottom: 0;
	}
	p {
		line-height: 2;
		margin: 10px 0 20px;
		&:first-of-type {
			margin-top:0;
		}
	}
	&--side {
		padding:20px;
		@include mq-sp {
			padding:20px 35px;
			margin-bottom: 20px;
			background: getColor('light-gray');
		};
	}
	&__header {
		padding:0 12px;
		background:#BABABA;
		color: getColor('white');
		font-family: $_font-family-noto;
		font-weight: 900;
		line-height: 2;
	}
}
