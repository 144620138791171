/*!
 * Container
 */
.m-container {
	width: 880px;
	margin: 0 auto;
	&._header {
		width: 960px;
	}
	@include mq-sp {
		width: auto;
	};
}
