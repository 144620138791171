/**
 * Media Queries
 */

// タブレット
@mixin mq-tab {
	@media only screen and (max-width: $_breakpoint-tab-max) and (min-width: $_breakpoint-sp-max + 1) {
		@content;
	}
}

// スマートフォン
@mixin mq-sp {
	@media only screen and (max-width: $_breakpoint-sp-max) {
		@content;
	}
}

// 指定画面幅範囲
@mixin mq-range($max, $min) {
	@media only screen and (max-width: $max) and (min-width: $min) {
		@content;
	}
}

// 指定画面幅以上
@mixin mq-min($min) {
	@media only screen and (min-width: $min) {
		@content;
	}
}

// 指定画面幅以下
@mixin mq-max($max) {
	@media only screen and (max-width: $max) {
		@content;
	}
}

/**
 * Clearfix
 */

@mixin clearfix {
	*zoom: 1;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
