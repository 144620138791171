/*!
 * Body
 */

#body {
	@include clearfix;
	position: relative;
	display: flex;
	@include mq-sp {
		display: block;
		width: auto;
	};
}
