#information {
	@include clearfix;
	@include mq-sp {
	};
	ul {
		list-style: none;
		li {
			display: flex;
			margin: 0 0 20px 0;
			@include mq-sp {
				display: block;
				margin: 0 0 20px 0;
			};
			.date {
				margin-right:20px;
				font-family: $_font-family-noto;
				font-weight: 900;
				color: getColor('bordeaux');
			}
			.title {
				position: relative;
				flex-grow: 1;
				padding:15px;
				background: getColor('white');
				font-size: px2rem(12,14);
				font-weight: normal;
				&:before {
					position: absolute;
					display: block;
					content: '';
					top:6px;
					left:-10px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 10px 5px 0;
					border-color: transparent getColor('white') transparent transparent;
					@include mq-sp {
						display: none;
					};
				}
				a {
					color:getColor('black');
				}
			}
			&:first-of-type {
				.title {
					background: getColor('bordeaux');
					&:before {
						border-color: transparent getColor('bordeaux') transparent transparent;
					}
					a {
						color: getColor('white');
					}
				}
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.btn-more {
		display: inline-block;
		float:right;
		margin-left:auto;
		margin-bottom: 40px;
		color: getColor('bordeaux');
	}
}
#side #information {
	h2 {
		@include mq-sp {
			display: none;
		};
	}
	.btn-more {
		@include mq-sp {
			display: none;
		};
	}
	ul {
		@include mq-sp {
			margin:0 px2per(30,640) 20px;
			padding:20px 10px;
		};
		li {
			display: block;
			.date {
				display: block;
				text-align: center;
				margin-right:0;
				margin-bottom: 12px;
			}
			.title {
				font-size: px2rem(12,14);
				&:before {
					top:-8px;
					left:50%;
					margin-left:-5px;
					border-width: 0 5px 8px 5px ;
					border-color: transparent transparent getColor('white') transparent;
				}
			}
			&:first-of-type {
				.title {
					&:before {
						border-color: transparent transparent getColor('bordeaux') transparent;
					}
				}
			}
		}
	}
}
