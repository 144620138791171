.modal {
	&__overlay {
		display:none;
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100vh;
		background-color:rgba(0,0,0,.5);
		z-index:10;
	}
	&__btn {

	}
	&__content {
		display:none;
		position:fixed;
		z-index:20;
		margin:10px;
		padding:15px;
		max-width: 640px;
		width: 80%;
		min-height: 300px;
		border-radius:4px;
		background:#fff;
		h3 {
			padding-bottom: 0.4em;
			margin-bottom: 0.2em;
			border-bottom:1px solid getColor('bordeaux');
			font-size: px2rem(18,14);
			font-weight: normal;
			line-height: 1.2;
			color:#getColor('bordeaux');
			@include mq-sp {
				font-size: px2rem(14,14);
			};
		}
		p {
			word-break: break-all;
			@include mq-sp {
				line-height: 1.2;
			};
		}
	}
	&__date {
		display: block;
		color:#aaa;
		font-size: px2rem(12,14);
		margin-bottom: 18px;
	}
	&__close {
		position:absolute;
		display: block;
		top:-10px;
		right:-10px;
		width: 32px;
		height: 32px;
		background:getColor('white');
		border-radius: 16px;
		span {
			display: block;
			position: absolute;
			top:15px;
			left:8px;
			width: 16px;
			height: 3px;
			border-radius: 3px;
			background-color: getColor('bordeaux');
			&:nth-of-type(1) {
				transform: rotate(45deg);
			}
			&:nth-of-type(2) {
				transform: rotate(-45deg);
			}
		}
	}
}
