#top {
	#topic {
		@include mq-sp {
			margin: 0 px2per(15,375);
		};
		ul {
			display: flex;
			margin:0 0 50px;
			padding:0;
			list-style: none;
			@include mq-sp {
				margin:0 0 30px;
			};
			li {
				@include mq-sp {
					width: 30%;
				};
				&:nth-of-type(2) {
					flex-grow: 1;
					text-align: center;
					@include mq-sp {
						flex-grow: 0;
						margin:0 auto;
					};
				}
			}
		}
	}
	#about {
		.m-box > ul > li {
			list-style: none;
		}
		#about__ul {
			background:#FAFAFA;
			padding:15px 35px;
			margin-bottom: 40px;
			@include mq-sp {
				padding:1em px2per(15,375);
				margin-bottom: 20px;
			};
		}
	}
	#company {
		margin-bottom:40px;
	}
	.box {
		&__body {
			display: flex;
			align-items: center;
		}
		&__img {
			width: 180px;
			text-align: center;
			@include mq-sp {
				width: 30%;
				padding-right: 5%;
			};
		}
		&__data {
			@include mq-sp {
				width: 70%;
			};
			p {
				margin:0;
			}
			div , dl {
				padding: 0 0 0 8px;
				margin:0;
				border-left:2px solid getColor('bordeaux');
				font-size: px2rem(12,14);
				dd {
					padding: 0 0 6px 18px;
				}
			}
		}
	}
	#logo-campany {
		width: 140px;
	}
	#logo-808 {
		width: 100px;
	}
}
