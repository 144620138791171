/*!
 * Button
 */
 .m-button {
	span {
		display: none;
	}
	&--top {
   	position: fixed;
   	display: block;
   	bottom:2px;
   	left:50%;
   	margin-left: 395px;
   	@include sprite($btn-top);
    }
 }
 .m-btn {
	 display: inline-block;
	 padding: 0 12px;
	 background: #fff;
	 box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
	 border-radius: 4px;
	 font-weight: bold;
	 border: 1px solid #872328;
	 &:hover {
		 background-color: #872328;
		 color: white;
		 text-decoration: none;
	 }
 }
