@charset "UTF-8";
/**
 * Foundation
 */
/**
 * フォントサイズをpxからremに変換
 *
 * @param {num|str} $px remに変換したいフォントサイズ（px）
 * @param {num|str} $base-font-size 基準とするフォントサイズ（px）
 * @return {str} 変換結果（px）
 */
/**
 * pxから%に変換
 *
 * @param  {num|str} $px 変換対象の値（px）
 * @param  {num|str} $base 基準とする値（px）
 * @return {str} 変換結果（%）
 */
/**
 * $colorsから指定したキーの値を取得
 *
 * $param {str} $key $colorsのキー名
 * $return {color} カラーコード
 */
/**
 * Media Queries
 */
/**
 * Clearfix
 */
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
:root {
	font-size: 14px;
}

html {
	font-family: "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}

body, html {
	margin: 0;
}

body * {
	box-sizing: border-box;
}

h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
	margin-top: 0;
}

p, ul, ol {
	margin-bottom: 1em;
}

p:last-child, ul:last-child, ol:last-child {
	margin-bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

figure {
	text-align: center;
}

a {
	text-decoration: none;
	color: #872328;
}

a img {
	transition: opacity .1s ease;
	border-style: none;
}

a:hover {
	text-decoration: underline;
	color: #BC3744;
}

a:hover img {
	opacity: 0.8;
}

main {
	display: block;
}

a:focus, *:focus {
	outline: none;
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 250;
	src: url("../../fonts/NotoSansCJKjp-Thin.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Thin.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 300;
	src: url("../../fonts/NotoSansCJKjp-Light.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Light.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 350;
	src: url("../../fonts/NotoSansCJKjp-DemiLight.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-DemiLight.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 400;
	src: url("../../fonts/NotoSansCJKjp-Regular.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Regular.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 500;
	src: url("../../fonts/NotoSansCJKjp-Medium.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Medium.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 700;
	src: url("../../fonts/NotoSansCJKjp-Bold.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Bold.woff") format("woff");
}

@font-face {
	font-family: "Noto Sans JP";
	font-weight: 900;
	src: url("../../fonts/NotoSansCJKjp-Black.woff2") format("woff2"), url("../../fonts/NotoSansCJKjp-Black.woff") format("woff");
}

/**
 * Layout
 */
/*!
 * Header
 */
@media only screen and (max-width: 750px) {
	.header {
		position: fixed;
		top: 0;
		width: 100%;
		background: #872328;
		z-index: 10;
	}
}

@media only screen and (max-width: 750px) {
	.header .logo {
		margin-left: 4%;
		z-index: 15;
	}
}

.header .logo a {
	display: block;
	background-image: url(../../images/dist/sprite.png);
	background-position: 0px -92px;
	width: 190px;
	height: 40px;
}

@media only screen and (max-width: 750px) {
	.header .logo a {
		background-image: url(../../images/dist/sprite.png);
		background-position: 0px 0px;
		width: 197px;
		height: 41px;
		background-size: 197px 94px;
	}
}

.header .logo a span {
	display: none;
}

.header__sub {
	display: flex;
	align-items: center;
	background: #872328;
	color: #fff;
	font-size: 1rem;
}

@media only screen and (max-width: 750px) {
	.header__sub {
		display: none;
	}
}

.header__sub p {
	margin: 0;
	padding-right: 10px;
}

.header__main {
	display: flex;
	align-items: center;
	height: 60px;
}

@media only screen and (max-width: 750px) {
	.header__main {
		display: block;
		height: auto;
		padding: 10px 0;
	}
}

.header__main h1 {
	width: 190px;
}

@media only screen and (max-width: 750px) {
	.header__main.opened .btn-menu span {
		height: 2px;
	}
	.header__main.opened .btn-menu span:nth-of-type(1) {
		margin-top: 0;
		transform: rotate(45deg);
	}
	.header__main.opened .btn-menu span:nth-of-type(2) {
		display: none;
	}
	.header__main.opened .btn-menu span:nth-of-type(3) {
		margin-top: 0;
		transform: rotate(-45deg);
	}
}

/*!
 * Footer
 */
.footer {
	height: 50px;
	overflow-y: hidden;
	background: #333;
	text-align: center;
	line-height: 50px;
	color: #fff;
}

/*!
 * Nav
 */
.nav {
	display: flex;
	margin-left: auto;
}

@media only screen and (max-width: 750px) {
	.nav {
		display: none;
		position: absolute;
		width: 100%;
		top: 60px;
		left: 0;
	}
}

.nav_link {
	display: block;
	padding: 4px 0;
	margin: 0 10px;
	color: #000;
	border-bottom: 1px dotted #000;
	line-height: 1;
	transition: all .2s ease;
}

@media only screen and (max-width: 750px) {
	.nav_link {
		position: relative;
		padding: 1em;
	}
	.nav_link:before {
		position: absolute;
		display: block;
		content: '';
		top: 50%;
		left: 0;
		width: 0;
		height: 0;
		margin-top: -5px;
		border-style: solid;
		border-width: 5px 0 5px 6px;
		border-color: transparent transparent transparent #872328;
	}
}

.nav_link:hover {
	color: #872328;
	border-bottom: 1px solid #872328;
	text-decoration: none;
}

.nav_link.is-active {
	color: #872328;
	border-bottom: 1px solid #872328;
}

.nav_list {
	display: flex;
	list-style: none;
}

@media only screen and (max-width: 750px) {
	.nav_list {
		display: block;
		background: rgba(255, 255, 255, 0.95);
	}
}

.nav_item {
	position: relative;
}

@media only screen and (max-width: 750px) {
	.nav_item:last-of-type a {
		border-bottom: 0;
	}
}

@media only screen and (max-width: 750px) {
	.nav_item._has-child .nav_link:before {
		display: none;
	}
}

.nav_item._has-child:hover .nav_link:hover {
	color: #000;
	border-bottom: 1px dotted #000;
}

.nav_item._has-child:hover .nav_child {
	top: 18px;
	visibility: visible;
	opacity: 1;
}

@media only screen and (max-width: 750px) {
	.nav_item._has-child:hover .nav_child {
		top: auto;
	}
}

.nav_child {
	position: absolute;
	top: 8px;
	width: 240px;
	padding: 1em 0;
	opacity: 0;
	transition: all .2s ease;
}

@media only screen and (max-width: 750px) {
	.nav_child {
		position: relative;
		top: auto;
		opacity: 1;
	}
}

.nav_child li {
	background: #872328;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 750px) {
	.nav_child li {
		background: none;
	}
}

.nav_child li a {
	display: block;
	padding: 1em;
	color: #fff;
}

@media only screen and (max-width: 750px) {
	.nav_child li a {
		position: relative;
		padding: .5em 1em;
		margin: 0 2em;
		color: #000;
	}
	.nav_child li a:before {
		position: absolute;
		display: block;
		content: '';
		top: 50%;
		left: 0;
		width: 0;
		height: 0;
		margin-top: -5px;
		border-style: solid;
		border-width: 5px 0 5px 6px;
		border-color: transparent transparent transparent #872328;
	}
}

.btn-menu {
	display: none;
}

@media only screen and (max-width: 750px) {
	.btn-menu {
		position: absolute;
		display: block;
		width: 36px;
		height: 28px;
		top: 50%;
		right: 10px;
		margin-top: -14px;
	}
	.btn-menu span {
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		height: 3px;
		border-radius: 3px;
		background-color: #fff;
		transition: all .3s ease;
	}
	.btn-menu span:nth-of-type(1) {
		margin-top: -12px;
	}
	.btn-menu span:nth-of-type(3) {
		margin-top: 12px;
	}
}

/*!
 * Body
 */
#body {
	*zoom: 1;
	position: relative;
	display: flex;
}

#body:after {
	content: "";
	display: table;
	clear: both;
}

@media only screen and (max-width: 750px) {
	#body {
		display: block;
		width: auto;
	}
}

/*!
 * Main
 */
#main {
	width: 580px;
	margin: 0 auto 80px 0;
}

@media only screen and (max-width: 750px) {
	#main {
		width: auto;
		margin: 0 4% 40px;
	}
}

.main--center {
	margin: 0 auto 80px !important;
}

@media only screen and (max-width: 750px) {
	.main--center {
		margin: 0 4% 40px !important;
	}
}

/*!
 * Side
 */
#side {
	width: 280px;
	margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
	#side {
		width: auto;
		margin-bottom: 0;
		padding: 20px 0;
		background-color: #7C2B2C;
		background-size: 20px auto;
		background-image: linear-gradient(to right, #8f2a2a 50%, transparent 50%, transparent);
		background-repeat: repeat;
	}
}

#side__event, #side__link {
	padding: 20px 0;
	background-color: #7C2B2C;
	background-size: 20px auto;
	background-image: linear-gradient(to right, #8f2a2a 50%, transparent 50%, transparent);
	background-repeat: repeat;
	border-radius: 15px;
	text-align: center;
	color: #fff;
}

@media only screen and (max-width: 750px) {
	#side__event, #side__link {
		background: none;
		padding: 0;
	}
}

#side__event h2, #side__link h2 {
	font-size: 1.71429rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
}

#side__event li, #side__link li {
	margin-bottom: 15px;
}

#side__event li:last-of-type, #side__link li:last-of-type {
	margin: 0;
}

#side__event {
	margin-bottom: 20px;
}

#side__event a {
	display: block;
	background-color: #fff;
	text-align: center;
	margin: 1rem;
}

#side__event img {
	width: 240px;
}

#information {
	*zoom: 1;
}

#information:after {
	content: "";
	display: table;
	clear: both;
}

#information ul {
	list-style: none;
}

#information ul li {
	display: flex;
	margin: 0 0 20px 0;
}

@media only screen and (max-width: 750px) {
	#information ul li {
		display: block;
		margin: 0 0 20px 0;
	}
}

#information ul li .date {
	margin-right: 20px;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
	color: #872328;
}

#information ul li .title {
	position: relative;
	flex-grow: 1;
	padding: 15px;
	background: #fff;
	font-size: 0.85714rem;
	font-weight: normal;
}

#information ul li .title:before {
	position: absolute;
	display: block;
	content: '';
	top: 6px;
	left: -10px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 10px 5px 0;
	border-color: transparent #fff transparent transparent;
}

@media only screen and (max-width: 750px) {
	#information ul li .title:before {
		display: none;
	}
}

#information ul li .title a {
	color: #000;
}

#information ul li:first-of-type .title {
	background: #872328;
}

#information ul li:first-of-type .title:before {
	border-color: transparent #872328 transparent transparent;
}

#information ul li:first-of-type .title a {
	color: #fff;
}

#information ul li:last-of-type {
	margin-bottom: 0;
}

#information .btn-more {
	display: inline-block;
	float: right;
	margin-left: auto;
	margin-bottom: 40px;
	color: #872328;
}

@media only screen and (max-width: 750px) {
	#side #information h2 {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	#side #information .btn-more {
		display: none;
	}
}

@media only screen and (max-width: 750px) {
	#side #information ul {
		margin: 0 4.6875% 20px;
		padding: 20px 10px;
	}
}

#side #information ul li {
	display: block;
}

#side #information ul li .date {
	display: block;
	text-align: center;
	margin-right: 0;
	margin-bottom: 12px;
}

#side #information ul li .title {
	font-size: 0.85714rem;
}

#side #information ul li .title:before {
	top: -8px;
	left: 50%;
	margin-left: -5px;
	border-width: 0 5px 8px 5px;
	border-color: transparent transparent #fff transparent;
}

#side #information ul li:first-of-type .title:before {
	border-color: transparent transparent #872328 transparent;
}

/**
 * Module
 */
/*!
 * Button
 */
.m-button span {
	display: none;
}

.m-button--top {
	position: fixed;
	display: block;
	bottom: 2px;
	left: 50%;
	margin-left: 395px;
	background-image: url(../../images/dist/sprite.png);
	background-position: 0px -142px;
	width: 46px;
	height: 46px;
}

.m-btn {
	display: inline-block;
	padding: 0 12px;
	background: #fff;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	border-radius: 4px;
	font-weight: bold;
	border: 1px solid #872328;
}

.m-btn:hover {
	background-color: #872328;
	color: white;
	text-decoration: none;
}

/*!
 * Container
 */
.m-container {
	width: 880px;
	margin: 0 auto;
}

.m-container._header {
	width: 960px;
}

@media only screen and (max-width: 750px) {
	.m-container {
		width: auto;
	}
}

/*!
 * Lang
 */
.m-lang {
	display: flex;
	list-style: none;
	margin: 0 auto 0 0;
	padding: 0;
	line-height: 24 / 18;
}

@media only screen and (max-width: 750px) {
	.m-lang {
		display: none;
	}
}

.m-lang__button {
	width: 2.5em;
	text-align: center;
}

.m-lang__button a {
	display: block;
	color: #fff;
	font-size: 1.28571rem;
	background: #72171B;
	transition: all .2s ease;
}

.m-lang__button a:hover {
	text-decoration: none;
	background: #fff;
	color: #872328;
}

.m-lang__button.is-active a {
	background: #fff;
	color: #872328;
}

/*!
 * key
 */
.m-key {
	width: 960px;
	margin: 0 auto 60px;
}

@media only screen and (max-width: 750px) {
	.m-key {
		width: auto;
		margin: 60px auto 20px;
	}
}

.m-h {
	position: relative;
	margin-bottom: 25px;
	line-height: 1.5;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	color: #4D4D4D;
}

@media only screen and (max-width: 750px) {
	.m-h {
		margin-bottom: 15px;
	}
}

.m-h span {
	position: relative;
	background: #fff;
	z-index: 2;
	padding: 0 10px 0 18px;
	line-height: 1.5;
	font-size: 1.28571rem;
	vertical-align: middle;
}

@media only screen and (max-width: 750px) {
	.m-h span {
		font-size: 1rem;
	}
}

.m-h:before {
	position: absolute;
	content: '';
	display: block;
	background-image: url(../../images/dist/sprite.png);
	background-position: -200px -92px;
	width: 12px;
	height: 12px;
	left: 0;
	top: 50%;
	margin-top: -6px;
	z-index: 5;
}

.m-h:after {
	position: absolute;
	content: '';
	display: block;
	top: 50%;
	left: 0;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #D8D8D8;
	z-index: 1;
}

.m-h-sec {
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	margin-bottom: 15px;
}

.m-h-sec span {
	display: inline-block;
	height: 30px;
	padding: 0 36px;
	background-color: #7C2B2C;
	background-size: 10px auto;
	background-image: -webkit-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: -o-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: linear-gradient(to right, #8f2a2a 50%, transparent 50%, transparent);
	background-repeat: repeat;
	border-radius: 15px;
	font-size: 1rem;
	font-weight: normal;
	color: #fff;
	line-height: 30px;
}

@media only screen and (max-width: 750px) {
	.m-h-sec span {
		padding: 0.7em 1em;
		height: auto;
		line-height: 1;
	}
}

/*!
 * Box
 */
.m-box {
	padding: 20px 35px;
	margin-bottom: 20px;
	background: #F5F5F5;
}

@media only screen and (max-width: 750px) {
	.m-box {
		padding: 1em 4%;
		margin-bottom: 20px;
	}
}

.m-box:last-of-type {
	margin-bottom: 0;
}

.m-box p {
	line-height: 2;
	margin: 10px 0 20px;
}

.m-box p:first-of-type {
	margin-top: 0;
}

.m-box--side {
	padding: 20px;
}

@media only screen and (max-width: 750px) {
	.m-box--side {
		padding: 20px 35px;
		margin-bottom: 20px;
		background: #F5F5F5;
	}
}

.m-box__header {
	padding: 0 12px;
	background: #BABABA;
	color: #fff;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
	line-height: 2;
}

.m-list {
	padding-left: 1em;
	line-height: 2;
}

.m-list .m-table {
	text-align: center !important;
}

.m-list > li {
	position: relative;
	list-style: none;
}

.m-list > li:before {
	position: absolute;
	top: 0.75em;
	left: -1em;
	content: '';
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	background-color: #872328;
}

.m-list > li ul {
	padding-left: 18px;
}

.m-list > li ul li {
	position: relative;
	list-style: none;
}

.m-list > li ul li:before {
	position: absolute;
	top: 0.5em;
	left: -1em;
	content: '';
	display: block;
	width: 6px;
	height: 6px;
	border-radius: 3px;
	border: 1px solid #872328;
}

.m-list-sec {
	display: flex;
	list-style: none;
	align-items: center;
}

@media only screen and (max-width: 750px) {
	.m-list-sec {
		flex-direction: column;
	}
}

.m-list-sec li {
	display: flex;
	list-style: none;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 30%;
	height: 50px;
	margin-right: 10px;
	background: #fff;
	border: 1px solid #979797;
	border-radius: 25px;
	text-align: center;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
	font-size: 0.85714rem;
}

@media only screen and (max-width: 750px) {
	.m-list-sec li {
		flex-direction: row;
		width: 100%;
		height: auto;
		margin-right: 0;
		margin-bottom: 10px;
		padding: 0.7em 0;
		flex-direction: row;
	}
}

.m-list-sec li span {
	color: #872328;
}

.m-list-sec li:last-of-type {
	margin-right: none;
}

@media only screen and (max-width: 750px) {
	.m-list-sec li div:last-child {
		margin-left: 10px;
	}
}

.m-list-sub {
	list-style: none;
	font-size: 0.85714rem;
}

.m-list-sub li {
	margin-bottom: 6px;
}

.m-list-sub li:last-of-type {
	margin-bottom: 0;
}

.m-title {
	position: relative;
	width: 960px;
	margin: 0 auto 80px;
	line-height: 1;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
	color: #4D4D4D;
	text-align: center;
}

@media only screen and (max-width: 750px) {
	.m-title {
		width: 100%;
		margin: 0 auto 30px;
	}
}

.m-title span {
	position: relative;
	background: #fff;
	z-index: 2;
	padding: 0 10px;
	line-height: 1;
	font-size: 1.71429rem;
	vertical-align: middle;
}

@media only screen and (max-width: 750px) {
	.m-title span {
		font-size: 1.14286rem;
	}
}

.m-title:after {
	position: absolute;
	content: '';
	display: block;
	top: 50%;
	left: 0;
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #D8D8D8;
	z-index: 1;
}

.m-title__information {
	margin-top: 50px;
}

@media only screen and (max-width: 750px) {
	.m-title__information {
		margin-top: 80px;
	}
}

.modal__overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.modal__content {
	display: none;
	position: fixed;
	z-index: 20;
	margin: 10px;
	padding: 15px;
	max-width: 640px;
	width: 80%;
	min-height: 300px;
	border-radius: 4px;
	background: #fff;
}

.modal__content h3 {
	padding-bottom: 0.4em;
	margin-bottom: 0.2em;
	border-bottom: 1px solid #872328;
	font-size: 1.28571rem;
	font-weight: normal;
	line-height: 1.2;
	color: #getColor "bordeaux";
}

@media only screen and (max-width: 750px) {
	.modal__content h3 {
		font-size: 1rem;
	}
}

.modal__content p {
	word-break: break-all;
}

@media only screen and (max-width: 750px) {
	.modal__content p {
		line-height: 1.2;
	}
}

.modal__date {
	display: block;
	color: #aaa;
	font-size: 0.85714rem;
	margin-bottom: 18px;
}

.modal__close {
	position: absolute;
	display: block;
	top: -10px;
	right: -10px;
	width: 32px;
	height: 32px;
	background: #fff;
	border-radius: 16px;
}

.modal__close span {
	display: block;
	position: absolute;
	top: 15px;
	left: 8px;
	width: 16px;
	height: 3px;
	border-radius: 3px;
	background-color: #872328;
}

.modal__close span:nth-of-type(1) {
	transform: rotate(45deg);
}

.modal__close span:nth-of-type(2) {
	transform: rotate(-45deg);
}

.pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
}

.pagination > li {
	display: inline;
}

.pagination > li > a {
	background: #fafafa;
	color: #666;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #a2161b;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
	margin-left: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
	color: #777;
	cursor: not-allowed;
	background-color: #fff;
	border-color: #ddd;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #a2161b;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #a2161b;
	border-color: #a2161b;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #a2161b;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.pagination > li > a {
	background: #fafafa;
	color: #666;
}

.pagination > li > a, .pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.42857143;
	color: #a2161b;
	text-decoration: none;
	background-color: #fff;
	border: 1px solid #ddd;
}

.m-table {
	width: 100%;
}

@media only screen and (max-width: 750px) {
	.m-table {
		width: 150%;
	}
}

@media only screen and (max-width: 750px) {
	.m-table caption {
		text-align: left;
	}
}

.m-table th {
	padding: 0.5em 0;
	background-color: #872328;
	border-bottom: 1px solid #F5F5F5;
	border-right: 1px solid #F5F5F5;
	color: #fff;
	font-weight: normal;
}

.m-table th:last-of-type {
	border-right: 0;
}

@media only screen and (max-width: 750px) {
	.m-table th {
		padding: 0.5em 0.2em;
	}
}

.m-table td {
	padding: 1em 0.5em;
	background-color: #fff;
	border-bottom: 1px solid #F5F5F5;
	border-right: 1px solid #F5F5F5;
	font-size: 0.85714rem;
	text-align: center;
}

.m-table td:last-of-type {
	border-right: 0;
}

@media only screen and (max-width: 750px) {
	.m-table td {
		width: 90px;
	}
}

.m-table td span {
	display: block;
	padding: 0.3em;
	background-color: #ddd;
	border-radius: 4px;
}

.m-table tr:last-of-type td {
	border-bottom: 0;
}

/**
 * Component
 */
/**
 * Page
 */
@media only screen and (max-width: 750px) {
	#top #topic {
		margin: 0 4%;
	}
}

#top #topic ul {
	display: flex;
	margin: 0 0 50px;
	padding: 0;
	list-style: none;
}

@media only screen and (max-width: 750px) {
	#top #topic ul {
		margin: 0 0 30px;
	}
}

@media only screen and (max-width: 750px) {
	#top #topic ul li {
		width: 30%;
	}
}

#top #topic ul li:nth-of-type(2) {
	flex-grow: 1;
	text-align: center;
}

@media only screen and (max-width: 750px) {
	#top #topic ul li:nth-of-type(2) {
		flex-grow: 0;
		margin: 0 auto;
	}
}

#top #about .m-box > ul > li {
	list-style: none;
}

#top #about #about__ul {
	background: #FAFAFA;
	padding: 15px 35px;
	margin-bottom: 40px;
}

@media only screen and (max-width: 750px) {
	#top #about #about__ul {
		padding: 1em 4%;
		margin-bottom: 20px;
	}
}

#top #company {
	margin-bottom: 40px;
}

#top .box__body {
	display: flex;
	align-items: center;
}

#top .box__img {
	width: 180px;
	text-align: center;
}

@media only screen and (max-width: 750px) {
	#top .box__img {
		width: 30%;
		padding-right: 5%;
	}
}

@media only screen and (max-width: 750px) {
	#top .box__data {
		width: 70%;
	}
}

#top .box__data p {
	margin: 0;
}

#top .box__data div, #top .box__data dl {
	padding: 0 0 0 8px;
	margin: 0;
	border-left: 2px solid #872328;
	font-size: 0.85714rem;
}

#top .box__data div dd, #top .box__data dl dd {
	padding: 0 0 6px 18px;
}

#top #logo-campany {
	width: 140px;
}

#top #logo-808 {
	width: 100px;
}

#about__description {
	line-height: 2;
}

#about #mission {
	margin-bottom: 40px;
}

#about #club, #about #school, #about #registration {
	margin-bottom: 40px;
}

#about #club section, #about #school section, #about #registration section {
	margin-bottom: 20px;
}

#about #club section:last-of-type, #about #school section:last-of-type, #about #registration section:last-of-type {
	margin-bottom: 0;
}

#about #private {
	position: relative;
}

#about #private p {
	line-height: 2;
	padding-bottom: 160px;
}

@media only screen and (max-width: 750px) {
	#about #private p {
		padding: 0;
	}
	#about #private p br {
		display: none;
	}
}

#about #private img {
	position: absolute;
	bottom: 0;
	right: 0;
}

@media only screen and (max-width: 750px) {
	#about #private img {
		position: relative;
		bottom: auto;
		right: auto;
	}
}

#coach #main section ul {
	list-style: none;
}

.coach {
	display: flex;
	margin-bottom: 50px;
}

@media only screen and (max-width: 750px) {
	.coach {
		flex-direction: column;
	}
}

.coach__head {
	border-top: 1px solid #CBCBCB;
	border-bottom: 1px solid #CBCBCB;
	padding: 15px 0;
	margin-bottom: 2em;
}

.coach__head h3 {
	font-size: 1.71429rem;
	color: #872328;
}

@media only screen and (max-width: 750px) {
	.coach__head h3 {
		text-align: center;
	}
}

.coach__head p {
	font-size: 0.85714rem;
}

@media only screen and (max-width: 750px) {
	.coach__head p {
		text-align: center;
	}
}

.coach__head p br {
	display: none;
}

@media only screen and (max-width: 750px) {
	.coach__head p br {
		display: block;
	}
}

@media only screen and (max-width: 750px) {
	.coach__head p span {
		display: none;
	}
}

.coach__content {
	width: 400px;
}

@media only screen and (max-width: 750px) {
	.coach__content {
		width: 100%;
	}
}

.coach__content section {
	margin-bottom: 20px;
}

.coach__content section:last-of-type {
	margin-bottom: 0;
}

.coach__content section h4 {
	display: inline-block;
	padding: 0.2em 1em;
	margin-bottom: 1em;
	background: #FAFAFA;
	border: 1px solid #979797;
	border-radius: 4px;
	font-weight: normal;
}

.coach__content section ul {
	list-style: none;
}

.coach__content section ul li {
	display: flex;
	margin-bottom: 0.8em;
	font-size: 0.85714rem;
}

.coach__content section ul li:last-of-type {
	margin-bottom: 0;
}

.coach__content section ul li span:first-of-type {
	padding: 0.2em 1em;
	margin-right: 1em;
	background: #B4B4B4;
	border-radius: 4px;
	color: #fff;
}

.coach__content.opened .coach__btn span:last-child {
	transform: rotate(0);
}

@media only screen and (max-width: 750px) {
	.coach__prof {
		display: none;
		padding: 10px;
		background: #eee;
	}
}

.coach__btn {
	display: none;
	position: relative;
	padding: 0.5em 0;
	background: #eee;
	color: #888;
	text-align: center;
	text-decoration: none;
}

@media only screen and (max-width: 750px) {
	.coach__btn {
		display: block;
	}
}

.coach__btn:hover {
	color: #888;
	text-decoration: none;
}

.coach__icon {
	position: absolute;
	display: block;
	width: 5.33333%;
	height: auto;
	padding-top: 5.33333%;
	margin: -2.66667% 5.33333% 0 0;
	top: 50%;
	right: 0;
}

.coach__icon span {
	width: 100%;
	height: 1px;
	background-color: #888;
	display: block;
	position: absolute;
	top: 50%;
	transition: all .3s ease;
}

.coach__icon span:last-child {
	transform: rotate(90deg);
}

.coach__img {
	width: 180px;
}

@media only screen and (max-width: 750px) {
	.coach__img {
		width: auto;
		text-align: center;
		margin-bottom: 10px;
	}
}

.coach__img img {
	width: 160px;
}

@media only screen and (max-width: 750px) {
	.coach__img img {
		width: 70%;
	}
}

#schedule #maps {
	margin-bottom: 40px;
}

#schedule #maps .map__container li:first-child {
	margin-bottom: 30px;
}

#schedule #maps .map__container li iframe {
	display: block;
	width: 100%;
}

#schedule #maps section {
	margin-bottom: 20px;
}

#schedule #maps section:last-of-type {
	margin-bottom: 0;
}

#schedule #maps section ul {
	list-style: none;
}

#schedule #weekly__container {
	width: 100%;
	overflow: scroll;
}

#schedule #weekly table {
	width: 100%;
}

@media only screen and (max-width: 750px) {
	#schedule #weekly table {
		width: 150%;
	}
}

@media only screen and (max-width: 750px) {
	#schedule #weekly table caption {
		text-align: left;
	}
}

#schedule #weekly table th {
	padding: 0.5em 0;
	background-color: #872328;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	color: #fff;
	font-weight: normal;
}

#schedule #weekly table th:last-of-type {
	border-right: 0;
}

@media only screen and (max-width: 750px) {
	#schedule #weekly table th {
		padding: 0.5em 0.2em;
	}
}

#schedule #weekly table td {
	padding: 1em 0.5em;
	background-color: #F5F5F5;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	font-size: 0.85714rem;
	text-align: center;
}

#schedule #weekly table td:last-of-type {
	border-right: 0;
}

@media only screen and (max-width: 750px) {
	#schedule #weekly table td {
		width: 90px;
	}
}

#schedule #weekly table td span {
	display: block;
	padding: 0.3em;
	background-color: #ddd;
	border-radius: 4px;
}

#schedule #weekly table tr:last-of-type td {
	border-bottom: 0;
}

.form {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	list-style: none;
}

@media only screen and (max-width: 750px) {
	.form {
		display: block;
	}
}

.form li {
	width: 280px;
	height: 240px;
	margin-bottom: 20px;
	background-color: #7C2B2C;
	background-size: 40px auto;
	background-image: -webkit-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: -o-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: linear-gradient(to right, #8f2a2a 50%, transparent 50%, transparent);
	background-repeat: repeat;
	border-radius: 15px;
	text-align: center;
	cursor: pointer;
}

@media only screen and (max-width: 750px) {
	.form li {
		width: 100%;
		height: auto;
		margin-bottom: 20px;
		padding: 20px 0;
	}
}

.form li:hover {
	opacity: 0.8;
}

.form li:nth-of-type(odd) {
	margin-right: auto;
}

.form li a {
	width: 100%;
	height: 100%;
	color: #fff;
}

.form li a:hover {
	text-decoration: none;
}

.form li span {
	display: block;
	line-height: 1;
}

.form__title {
	margin: 80px 0 0.5em;
	font-size: 1.28571rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
}

@media only screen and (max-width: 750px) {
	.form__title {
		margin: 0 0 0.5em;
	}
}

.form__sub {
	margin-bottom: 1em;
}

.form__download {
	width: 80%;
	padding: 0.4em 1em 0.4em 0;
	margin: 0 auto;
	background: url("../../images/dist/icon-pdf.png") no-repeat 90% center #fff;
	color: #872328;
	font-size: 1.71429rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
}

.form__download.is-xls {
	padding: 0.4em 1em;
	background: #fff;
}

.link {
	list-style: none;
	margin-bottom: 60px !important;
}

.link__item {
	margin-bottom: 40px;
}

.link__item:last-of-type {
	margin-bottom: 0;
}

.link__box {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 750px) {
	.link__box {
		display: block;
	}
	.link__box img {
		display: none;
	}
}

.link__content {
	margin-left: 20px;
	padding: 0.5em 1em;
	border-left: 2px solid #872328;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
}

@media only screen and (max-width: 750px) {
	.link__content {
		margin-left: 0;
		padding: 0.5em 1em;
	}
}

.link__content h3 {
	font-weight: 400;
}

.link__desc {
	margin-top: 0.5em;
	font-size: 0.85714rem;
	color: #888;
}

.link__img {
	max-width: 240px;
}

#contact form input[type="submit"] {
	display: block;
	width: 100%;
	padding: 0.5em;
	background: #72B700;
	font-size: 1.28571rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	color: #fff;
	line-height: 1;
	transition: all .2s ease;
}

#contact form input[type="submit"]:hover {
	opacity: 0.7;
}

#contact .form__label {
	display: block;
	margin-bottom: 20px;
}

#contact .form__label span {
	display: block;
	margin-bottom: 0.2em;
}

#contact .form__label.has-error {
	color: #f00;
}

#contact .form__label.has-error .form__input, #contact .form__label.has-error .form__textarea {
	border-color: #f00 !important;
}

#contact .form__input {
	width: 60%;
	border: 1px solid #ddd;
	padding: 0.4em 1em;
}

@media only screen and (max-width: 750px) {
	#contact .form__input {
		width: 100%;
	}
}

#contact .form__input--subject, #contact .form__input--email {
	width: 100%;
}

#contact .form__textarea {
	width: 100%;
	border: 1px solid #ddd;
	padding: 0.4em 1em;
}

#error .m-title {
	margin-top: 50px;
}

#error #main {
	width: 100%;
	text-align: center;
}

#soccerclinic .sc__ul {
	list-style: none;
}

#soccerclinic .sc__ul li {
	display: flex;
	align-items: center;
}

#soccerclinic .sc__ul li div:first-of-type {
	width: 100px;
	margin-right: 20px;
}

#soccerclinic .btn-download {
	display: block;
	width: 60%;
	padding: 1em 0;
	margin: 20px auto;
	background-color: #7C2B2C;
	background-size: 40px auto;
	background-image: -webkit-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: -o-linear-gradient(left, #8f2a2a 50%, transparent 50%, transparent);
	background-image: linear-gradient(to right, #8f2a2a 50%, transparent 50%, transparent);
	background-repeat: repeat;
	border-radius: 5px;
	text-align: center;
	color: #fff;
	text-decoration: none;
}

#soccerclinic .btn-download:hover {
	opacity: 0.8;
}

#soccerclinic .btn-download span {
	display: block;
}

#soccerclinic .btn-download span:first-of-type {
	margin: 0 0 0.5em;
	font-size: 1.28571rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
}

@media only screen and (max-width: 750px) {
	#soccerclinic .btn-download span:first-of-type {
		margin: 0 0 0.5em;
	}
}

#soccerclinic .btn-download span:last-of-type {
	width: 80%;
	padding: 0.2em 1em 0.2em 0;
	margin: 0 auto;
	background: url("../../images/dist/icon-pdf.png") no-repeat 90% center #fff;
	background-size: 14px auto;
	color: #872328;
	font-size: 1.28571rem;
	font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
	font-weight: 900;
}

/**
 * Tool
 */
.u-color-white {
	color: #fff !important;
}

.u-bg-white {
	background-color: #fff !important;
}

.u-color-black {
	color: #000 !important;
}

.u-bg-black {
	background-color: #000 !important;
}

.u-color-dark-gray {
	color: #333 !important;
}

.u-bg-dark-gray {
	background-color: #333 !important;
}

.u-color-bordeaux {
	color: #872328 !important;
}

.u-bg-bordeaux {
	background-color: #872328 !important;
}

.u-color-gray {
	color: #4D4D4D !important;
}

.u-bg-gray {
	background-color: #4D4D4D !important;
}

.u-color-gray2 {
	color: #888 !important;
}

.u-bg-gray2 {
	background-color: #888 !important;
}

.u-color-light-gray {
	color: #F5F5F5 !important;
}

.u-bg-light-gray {
	background-color: #F5F5F5 !important;
}

.u-color-green {
	color: #72B700 !important;
}

.u-bg-green {
	background-color: #72B700 !important;
}

.u-cf {
	overflow: hidden;
}

.u-cf-legacy {
	*zoom: 1;
}

.u-cf-legacy:after {
	content: "";
	display: table;
	clear: both;
}

.u-p-0 {
	padding: 0px !important;
}

.u-pt-0 {
	padding-top: 0px !important;
}

.u-pr-0 {
	padding-right: 0px !important;
}

.u-pb-0 {
	padding-bottom: 0px !important;
}

.u-pl-0 {
	padding-left: 0px !important;
}

.u-p-5 {
	padding: 5px !important;
}

.u-pt-5 {
	padding-top: 5px !important;
}

.u-pr-5 {
	padding-right: 5px !important;
}

.u-pb-5 {
	padding-bottom: 5px !important;
}

.u-pl-5 {
	padding-left: 5px !important;
}

.u-p-10 {
	padding: 10px !important;
}

.u-pt-10 {
	padding-top: 10px !important;
}

.u-pr-10 {
	padding-right: 10px !important;
}

.u-pb-10 {
	padding-bottom: 10px !important;
}

.u-pl-10 {
	padding-left: 10px !important;
}

.u-p-15 {
	padding: 15px !important;
}

.u-pt-15 {
	padding-top: 15px !important;
}

.u-pr-15 {
	padding-right: 15px !important;
}

.u-pb-15 {
	padding-bottom: 15px !important;
}

.u-pl-15 {
	padding-left: 15px !important;
}

.u-p-20 {
	padding: 20px !important;
}

.u-pt-20 {
	padding-top: 20px !important;
}

.u-pr-20 {
	padding-right: 20px !important;
}

.u-pb-20 {
	padding-bottom: 20px !important;
}

.u-pl-20 {
	padding-left: 20px !important;
}

.u-p-25 {
	padding: 25px !important;
}

.u-pt-25 {
	padding-top: 25px !important;
}

.u-pr-25 {
	padding-right: 25px !important;
}

.u-pb-25 {
	padding-bottom: 25px !important;
}

.u-pl-25 {
	padding-left: 25px !important;
}

.u-p-30 {
	padding: 30px !important;
}

.u-pt-30 {
	padding-top: 30px !important;
}

.u-pr-30 {
	padding-right: 30px !important;
}

.u-pb-30 {
	padding-bottom: 30px !important;
}

.u-pl-30 {
	padding-left: 30px !important;
}

.u-p-35 {
	padding: 35px !important;
}

.u-pt-35 {
	padding-top: 35px !important;
}

.u-pr-35 {
	padding-right: 35px !important;
}

.u-pb-35 {
	padding-bottom: 35px !important;
}

.u-pl-35 {
	padding-left: 35px !important;
}

.u-p-40 {
	padding: 40px !important;
}

.u-pt-40 {
	padding-top: 40px !important;
}

.u-pr-40 {
	padding-right: 40px !important;
}

.u-pb-40 {
	padding-bottom: 40px !important;
}

.u-pl-40 {
	padding-left: 40px !important;
}

.u-p-45 {
	padding: 45px !important;
}

.u-pt-45 {
	padding-top: 45px !important;
}

.u-pr-45 {
	padding-right: 45px !important;
}

.u-pb-45 {
	padding-bottom: 45px !important;
}

.u-pl-45 {
	padding-left: 45px !important;
}

.u-p-50 {
	padding: 50px !important;
}

.u-pt-50 {
	padding-top: 50px !important;
}

.u-pr-50 {
	padding-right: 50px !important;
}

.u-pb-50 {
	padding-bottom: 50px !important;
}

.u-pl-50 {
	padding-left: 50px !important;
}

.u-p-55 {
	padding: 55px !important;
}

.u-pt-55 {
	padding-top: 55px !important;
}

.u-pr-55 {
	padding-right: 55px !important;
}

.u-pb-55 {
	padding-bottom: 55px !important;
}

.u-pl-55 {
	padding-left: 55px !important;
}

.u-p-60 {
	padding: 60px !important;
}

.u-pt-60 {
	padding-top: 60px !important;
}

.u-pr-60 {
	padding-right: 60px !important;
}

.u-pb-60 {
	padding-bottom: 60px !important;
}

.u-pl-60 {
	padding-left: 60px !important;
}

.u-p-65 {
	padding: 65px !important;
}

.u-pt-65 {
	padding-top: 65px !important;
}

.u-pr-65 {
	padding-right: 65px !important;
}

.u-pb-65 {
	padding-bottom: 65px !important;
}

.u-pl-65 {
	padding-left: 65px !important;
}

.u-p-70 {
	padding: 70px !important;
}

.u-pt-70 {
	padding-top: 70px !important;
}

.u-pr-70 {
	padding-right: 70px !important;
}

.u-pb-70 {
	padding-bottom: 70px !important;
}

.u-pl-70 {
	padding-left: 70px !important;
}

.u-p-75 {
	padding: 75px !important;
}

.u-pt-75 {
	padding-top: 75px !important;
}

.u-pr-75 {
	padding-right: 75px !important;
}

.u-pb-75 {
	padding-bottom: 75px !important;
}

.u-pl-75 {
	padding-left: 75px !important;
}

.u-p-80 {
	padding: 80px !important;
}

.u-pt-80 {
	padding-top: 80px !important;
}

.u-pr-80 {
	padding-right: 80px !important;
}

.u-pb-80 {
	padding-bottom: 80px !important;
}

.u-pl-80 {
	padding-left: 80px !important;
}

.u-p-85 {
	padding: 85px !important;
}

.u-pt-85 {
	padding-top: 85px !important;
}

.u-pr-85 {
	padding-right: 85px !important;
}

.u-pb-85 {
	padding-bottom: 85px !important;
}

.u-pl-85 {
	padding-left: 85px !important;
}

.u-p-90 {
	padding: 90px !important;
}

.u-pt-90 {
	padding-top: 90px !important;
}

.u-pr-90 {
	padding-right: 90px !important;
}

.u-pb-90 {
	padding-bottom: 90px !important;
}

.u-pl-90 {
	padding-left: 90px !important;
}

.u-p-95 {
	padding: 95px !important;
}

.u-pt-95 {
	padding-top: 95px !important;
}

.u-pr-95 {
	padding-right: 95px !important;
}

.u-pb-95 {
	padding-bottom: 95px !important;
}

.u-pl-95 {
	padding-left: 95px !important;
}

.u-p-100 {
	padding: 100px !important;
}

.u-pt-100 {
	padding-top: 100px !important;
}

.u-pr-100 {
	padding-right: 100px !important;
}

.u-pb-100 {
	padding-bottom: 100px !important;
}

.u-pl-100 {
	padding-left: 100px !important;
}

.u-p-em-025 {
	padding: 0.25em !important;
}

.u-pt-em-025 {
	padding-top: 0.25em !important;
}

.u-pr-em-025 {
	padding-right: 0.25em !important;
}

.u-pb-em-025 {
	padding-bottom: 0.25em !important;
}

.u-pl-em-025 {
	padding-left: 0.25em !important;
}

.u-p-em-050 {
	padding: 0.5em !important;
}

.u-pt-em-050 {
	padding-top: 0.5em !important;
}

.u-pr-em-050 {
	padding-right: 0.5em !important;
}

.u-pb-em-050 {
	padding-bottom: 0.5em !important;
}

.u-pl-em-050 {
	padding-left: 0.5em !important;
}

.u-p-em-075 {
	padding: 0.75em !important;
}

.u-pt-em-075 {
	padding-top: 0.75em !important;
}

.u-pr-em-075 {
	padding-right: 0.75em !important;
}

.u-pb-em-075 {
	padding-bottom: 0.75em !important;
}

.u-pl-em-075 {
	padding-left: 0.75em !important;
}

.u-p-em-100 {
	padding: 1em !important;
}

.u-pt-em-100 {
	padding-top: 1em !important;
}

.u-pr-em-100 {
	padding-right: 1em !important;
}

.u-pb-em-100 {
	padding-bottom: 1em !important;
}

.u-pl-em-100 {
	padding-left: 1em !important;
}

.u-p-em-125 {
	padding: 1.25em !important;
}

.u-pt-em-125 {
	padding-top: 1.25em !important;
}

.u-pr-em-125 {
	padding-right: 1.25em !important;
}

.u-pb-em-125 {
	padding-bottom: 1.25em !important;
}

.u-pl-em-125 {
	padding-left: 1.25em !important;
}

.u-p-em-150 {
	padding: 1.5em !important;
}

.u-pt-em-150 {
	padding-top: 1.5em !important;
}

.u-pr-em-150 {
	padding-right: 1.5em !important;
}

.u-pb-em-150 {
	padding-bottom: 1.5em !important;
}

.u-pl-em-150 {
	padding-left: 1.5em !important;
}

.u-p-em-175 {
	padding: 1.75em !important;
}

.u-pt-em-175 {
	padding-top: 1.75em !important;
}

.u-pr-em-175 {
	padding-right: 1.75em !important;
}

.u-pb-em-175 {
	padding-bottom: 1.75em !important;
}

.u-pl-em-175 {
	padding-left: 1.75em !important;
}

.u-p-em-200 {
	padding: 2em !important;
}

.u-pt-em-200 {
	padding-top: 2em !important;
}

.u-pr-em-200 {
	padding-right: 2em !important;
}

.u-pb-em-200 {
	padding-bottom: 2em !important;
}

.u-pl-em-200 {
	padding-left: 2em !important;
}

.u-m-0 {
	margin: 0px !important;
}

.u-mt-0 {
	margin-top: 0px !important;
}

.u-mr-0 {
	margin-right: 0px !important;
}

.u-mb-0 {
	margin-bottom: 0px !important;
}

.u-ml-0 {
	margin-left: 0px !important;
}

.u-m-5 {
	margin: 5px !important;
}

.u-mt-5 {
	margin-top: 5px !important;
}

.u-mr-5 {
	margin-right: 5px !important;
}

.u-mb-5 {
	margin-bottom: 5px !important;
}

.u-ml-5 {
	margin-left: 5px !important;
}

.u-m-10 {
	margin: 10px !important;
}

.u-mt-10 {
	margin-top: 10px !important;
}

.u-mr-10 {
	margin-right: 10px !important;
}

.u-mb-10 {
	margin-bottom: 10px !important;
}

.u-ml-10 {
	margin-left: 10px !important;
}

.u-m-15 {
	margin: 15px !important;
}

.u-mt-15 {
	margin-top: 15px !important;
}

.u-mr-15 {
	margin-right: 15px !important;
}

.u-mb-15 {
	margin-bottom: 15px !important;
}

.u-ml-15 {
	margin-left: 15px !important;
}

.u-m-20 {
	margin: 20px !important;
}

.u-mt-20 {
	margin-top: 20px !important;
}

.u-mr-20 {
	margin-right: 20px !important;
}

.u-mb-20 {
	margin-bottom: 20px !important;
}

.u-ml-20 {
	margin-left: 20px !important;
}

.u-m-25 {
	margin: 25px !important;
}

.u-mt-25 {
	margin-top: 25px !important;
}

.u-mr-25 {
	margin-right: 25px !important;
}

.u-mb-25 {
	margin-bottom: 25px !important;
}

.u-ml-25 {
	margin-left: 25px !important;
}

.u-m-30 {
	margin: 30px !important;
}

.u-mt-30 {
	margin-top: 30px !important;
}

.u-mr-30 {
	margin-right: 30px !important;
}

.u-mb-30 {
	margin-bottom: 30px !important;
}

.u-ml-30 {
	margin-left: 30px !important;
}

.u-m-35 {
	margin: 35px !important;
}

.u-mt-35 {
	margin-top: 35px !important;
}

.u-mr-35 {
	margin-right: 35px !important;
}

.u-mb-35 {
	margin-bottom: 35px !important;
}

.u-ml-35 {
	margin-left: 35px !important;
}

.u-m-40 {
	margin: 40px !important;
}

.u-mt-40 {
	margin-top: 40px !important;
}

.u-mr-40 {
	margin-right: 40px !important;
}

.u-mb-40 {
	margin-bottom: 40px !important;
}

.u-ml-40 {
	margin-left: 40px !important;
}

.u-m-45 {
	margin: 45px !important;
}

.u-mt-45 {
	margin-top: 45px !important;
}

.u-mr-45 {
	margin-right: 45px !important;
}

.u-mb-45 {
	margin-bottom: 45px !important;
}

.u-ml-45 {
	margin-left: 45px !important;
}

.u-m-50 {
	margin: 50px !important;
}

.u-mt-50 {
	margin-top: 50px !important;
}

.u-mr-50 {
	margin-right: 50px !important;
}

.u-mb-50 {
	margin-bottom: 50px !important;
}

.u-ml-50 {
	margin-left: 50px !important;
}

.u-m-55 {
	margin: 55px !important;
}

.u-mt-55 {
	margin-top: 55px !important;
}

.u-mr-55 {
	margin-right: 55px !important;
}

.u-mb-55 {
	margin-bottom: 55px !important;
}

.u-ml-55 {
	margin-left: 55px !important;
}

.u-m-60 {
	margin: 60px !important;
}

.u-mt-60 {
	margin-top: 60px !important;
}

.u-mr-60 {
	margin-right: 60px !important;
}

.u-mb-60 {
	margin-bottom: 60px !important;
}

.u-ml-60 {
	margin-left: 60px !important;
}

.u-m-65 {
	margin: 65px !important;
}

.u-mt-65 {
	margin-top: 65px !important;
}

.u-mr-65 {
	margin-right: 65px !important;
}

.u-mb-65 {
	margin-bottom: 65px !important;
}

.u-ml-65 {
	margin-left: 65px !important;
}

.u-m-70 {
	margin: 70px !important;
}

.u-mt-70 {
	margin-top: 70px !important;
}

.u-mr-70 {
	margin-right: 70px !important;
}

.u-mb-70 {
	margin-bottom: 70px !important;
}

.u-ml-70 {
	margin-left: 70px !important;
}

.u-m-75 {
	margin: 75px !important;
}

.u-mt-75 {
	margin-top: 75px !important;
}

.u-mr-75 {
	margin-right: 75px !important;
}

.u-mb-75 {
	margin-bottom: 75px !important;
}

.u-ml-75 {
	margin-left: 75px !important;
}

.u-m-80 {
	margin: 80px !important;
}

.u-mt-80 {
	margin-top: 80px !important;
}

.u-mr-80 {
	margin-right: 80px !important;
}

.u-mb-80 {
	margin-bottom: 80px !important;
}

.u-ml-80 {
	margin-left: 80px !important;
}

.u-m-85 {
	margin: 85px !important;
}

.u-mt-85 {
	margin-top: 85px !important;
}

.u-mr-85 {
	margin-right: 85px !important;
}

.u-mb-85 {
	margin-bottom: 85px !important;
}

.u-ml-85 {
	margin-left: 85px !important;
}

.u-m-90 {
	margin: 90px !important;
}

.u-mt-90 {
	margin-top: 90px !important;
}

.u-mr-90 {
	margin-right: 90px !important;
}

.u-mb-90 {
	margin-bottom: 90px !important;
}

.u-ml-90 {
	margin-left: 90px !important;
}

.u-m-95 {
	margin: 95px !important;
}

.u-mt-95 {
	margin-top: 95px !important;
}

.u-mr-95 {
	margin-right: 95px !important;
}

.u-mb-95 {
	margin-bottom: 95px !important;
}

.u-ml-95 {
	margin-left: 95px !important;
}

.u-m-100 {
	margin: 100px !important;
}

.u-mt-100 {
	margin-top: 100px !important;
}

.u-mr-100 {
	margin-right: 100px !important;
}

.u-mb-100 {
	margin-bottom: 100px !important;
}

.u-ml-100 {
	margin-left: 100px !important;
}

.u-m-em-025 {
	margin: 0.25em !important;
}

.u-mt-em-025 {
	margin-top: 0.25em !important;
}

.u-mr-em-025 {
	margin-right: 0.25em !important;
}

.u-mb-em-025 {
	margin-bottom: 0.25em !important;
}

.u-ml-em-025 {
	margin-left: 0.25em !important;
}

.u-m-em-050 {
	margin: 0.5em !important;
}

.u-mt-em-050 {
	margin-top: 0.5em !important;
}

.u-mr-em-050 {
	margin-right: 0.5em !important;
}

.u-mb-em-050 {
	margin-bottom: 0.5em !important;
}

.u-ml-em-050 {
	margin-left: 0.5em !important;
}

.u-m-em-075 {
	margin: 0.75em !important;
}

.u-mt-em-075 {
	margin-top: 0.75em !important;
}

.u-mr-em-075 {
	margin-right: 0.75em !important;
}

.u-mb-em-075 {
	margin-bottom: 0.75em !important;
}

.u-ml-em-075 {
	margin-left: 0.75em !important;
}

.u-m-em-100 {
	margin: 1em !important;
}

.u-mt-em-100 {
	margin-top: 1em !important;
}

.u-mr-em-100 {
	margin-right: 1em !important;
}

.u-mb-em-100 {
	margin-bottom: 1em !important;
}

.u-ml-em-100 {
	margin-left: 1em !important;
}

.u-m-em-125 {
	margin: 1.25em !important;
}

.u-mt-em-125 {
	margin-top: 1.25em !important;
}

.u-mr-em-125 {
	margin-right: 1.25em !important;
}

.u-mb-em-125 {
	margin-bottom: 1.25em !important;
}

.u-ml-em-125 {
	margin-left: 1.25em !important;
}

.u-m-em-150 {
	margin: 1.5em !important;
}

.u-mt-em-150 {
	margin-top: 1.5em !important;
}

.u-mr-em-150 {
	margin-right: 1.5em !important;
}

.u-mb-em-150 {
	margin-bottom: 1.5em !important;
}

.u-ml-em-150 {
	margin-left: 1.5em !important;
}

.u-m-em-175 {
	margin: 1.75em !important;
}

.u-mt-em-175 {
	margin-top: 1.75em !important;
}

.u-mr-em-175 {
	margin-right: 1.75em !important;
}

.u-mb-em-175 {
	margin-bottom: 1.75em !important;
}

.u-ml-em-175 {
	margin-left: 1.75em !important;
}

.u-m-em-200 {
	margin: 2em !important;
}

.u-mt-em-200 {
	margin-top: 2em !important;
}

.u-mr-em-200 {
	margin-right: 2em !important;
}

.u-mb-em-200 {
	margin-bottom: 2em !important;
}

.u-ml-em-200 {
	margin-left: 2em !important;
}

.u-disp-n {
	display: none !important;
}

.u-disp-b {
	display: block !important;
}

.u-disp-ib {
	display: inline-block !important;
}

.u-pos-re {
	position: relative !important;
}

.u-pos-ab {
	position: absolute !important;
}

.u-post-st {
	position: static !important;
}

.u-l-s-n {
	list-style: none;
}

.u-t-c {
	text-align: center !important;
}

.u-t-r {
	text-align: right !important;
}
