#soccerclinic {
	.sc {
		&__ul {
			list-style: none;
			li {
				display: flex;
				align-items: center;
				div {
					&:first-of-type {
						width: 100px;
						margin-right: 20px;
					}
					img {

					}
					h4 {

					}
					p {

					}
				}
			}
		}
	}
	.btn-download {
		display: block;
		width: 60%;
		padding:1em 0;
		margin: 20px auto;
		background-color: #7C2B2C;
		background-size:40px auto;
		background-image:-webkit-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:-o-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:linear-gradient(to right, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-repeat: repeat;
		border-radius: 5px;
		text-align: center;
		color: getColor('white');
		text-decoration: none;
		&:hover {
			opacity: 0.8;
		}
		span {
			display: block;
			&:first-of-type {
				margin: 0 0 0.5em;
				font-size:px2rem(18,14);
				font-family: $_font-family-noto;
				font-weight: 900;
				@include mq-sp {
					margin: 0 0 0.5em;
				};
			}
			&:last-of-type {
				width: 80%;
				padding:0.2em 1em 0.2em 0;
				margin:0 auto;
				background: url('../../images/dist/icon-pdf.png') no-repeat 90% center getColor('white');
				background-size: 14px auto;
				color:getColor('bordeaux');
				font-size:px2rem(18,14);
				font-family: $_font-family-noto;
				font-weight: 900;
			}
		}
	}
}
