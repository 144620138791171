.m-h {
	position: relative;
	margin-bottom:25px;
	line-height: 1.5;
	font-family: $_font-family-noto;
	color: getColor('gray');
	@include mq-sp {
		margin-bottom:15px;
	};
	span {
		position: relative;
		background:getColor('white');
		z-index: 2;
		padding:0 10px 0 18px;
		line-height: 1.5;
		font-size: px2rem(18,14);
		vertical-align: middle;
		@include mq-sp {
			font-size: px2rem(14,14);
		};
	}
	&:before {
		position: absolute;
		content: '';
		display: block;
		@include sprite($icon-circle-red);
		left:0;
		top:50%;
		margin-top: -($icon-circle-red-height / 2);
		z-index: 5;
	}
	&:after {
		position: absolute;
		content: '';
		display: block;
		top:50%;
		left:0;
		width: 100%;
		height:1px;
		border-bottom:1px solid #D8D8D8;
		z-index: 1;
	}
}
.m-h-sec {
	font-family: $_font-family-noto;
	margin-bottom:15px;
	span {
		display:inline-block;
		height:30px;
		padding:0 36px;
		background-color: #7C2B2C;
		background-size:10px auto;
		background-image:-webkit-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:-o-linear-gradient(left, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-image:linear-gradient(to right, rgba(143, 42, 42, 1) 50%, transparent 50%, transparent);
		background-repeat: repeat;
		border-radius: 15px;
		font-size: px2rem(14,14);
		font-weight: normal;
		color: getColor('white');
		line-height: 30px;
		@include mq-sp {
			padding:0.7em 1em;
			height: auto;
			line-height: 1;
		};
	}
}
