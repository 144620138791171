/*!
 * Header
 */

.header {
	@include mq-sp {
		position: fixed;
		top: 0;
		width: 100%;
		background: getColor('bordeaux');
		z-index: 10;
	};
	.logo {
		@include mq-sp {
			margin-left: px2per(15, 375);
			z-index: 15;
		};
		a {
			display: block;
			@include sprite($logo);
			@include mq-sp {
				@include r-sprite($logo-sp);
			};
			span {
				display: none;
			}
		}
	}
	&__sub {
		display: flex;
		align-items: center;
		background: getColor('bordeaux');
		color: getColor('white');
		font-size: px2rem(14,14);
		@include mq-sp {
			display: none;
		};
		p {
			margin:0;
			padding-right: 10px;
		}
	}
	&__main {
		display: flex;
		align-items: center;
		height:60px;
		@include mq-sp {
			display: block;
			height: auto;
			padding:10px 0;
		};
		h1 {
			width: 190px;
		}
		&.opened {
			.btn-menu {
				@include mq-sp {
					span {
						height: 2px;
						&:nth-of-type(1) {
							margin-top: 0;
							transform: rotate(45deg);
						}
						&:nth-of-type(2) {
							display: none;
						}
						&:nth-of-type(3) {
							margin-top: 0;
							transform: rotate(-45deg);
						}
					}
				};
			}
		}
	}
}
