/*!
 * key
 */
.m-key {
	width: 960px;
	margin:0 auto 60px;
	@include mq-sp {
		width: auto;
		margin:60px auto 20px;
	};
}
